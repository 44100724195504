import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserWhite from "../../assets/icons/UserWhite.svg";
import Purusha from "../../assets/icons/Purusha.svg";
import CircularProgress from "@mui/material/CircularProgress";

export default function SimpleAccordion({ agents }) {
  const navigate = useNavigate();
  const agentsByOwner = agents.reduce((acc, agent) => {
    if (acc[agent.app_owner_name]) {
      acc[agent.app_owner_name].push(agent);
    } else {
      acc[agent.app_owner_name] = [agent];
    }
    return acc;
  }, {});

  const setAgent = (agent) => {
    localStorage.setItem("agent_id", agent.agent_id);
    navigate("/chat_bot");
  };

  const getDate = (dateStr) => {
    const date = new Date(dateStr);
    return new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
      timeZone: "Europe/London",
    }).format(date);
  };

  useEffect(() => {}, []);

  return (
    <Box>
      {Object.keys(agentsByOwner)?.length === 0 ? (
        <CircularProgress
          size={50}
          sx={{ position: "absolute", left: "50%", top: "50%" }}
        />
      ) : (
        <>
          {Object.keys(agentsByOwner).map((owner, index) => (
            <Accordion sx={{ backgroundColor: "#1C1C1C" }} key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel-header"
                sx={{ height: "94px", '.MuiAccordionSummary-content': {display: 'flex', justifyContent: 'space-between'} }}
              >
                <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: 'center'}}>
                <Box
                  key="user-image"
                  component="img"
                  src={UserWhite}
                  sx={{ pr: 2 }}
                />
                <Typography
                  key="user-name"
                  sx={{ flexShrink: 0 }}
                >
                  {owner}
                </Typography>
                </Box>
                <Typography
                  key="sessions"
                  sx={{ color: "text.secondary"}}
                >
                  {agentsByOwner[owner].length} Sessions
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor: "rgba(28, 28, 28, 0.3)",
                  borderRadius: "0px 0px 47px 47px",
                }}
              >
                {agentsByOwner[owner].map((agent, index) => (
                  <Box
                    key={index}
                    onClick={() => setAgent(agent)}
                    sx={{
                      display: "flex",
                      p: 2,
                      flexDirection: {xs: "column", md: "row"},
                      alignItems: 'center',
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ width: "150px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: {xs: "column", md: "row"},
                          alignItems: "center",
                        }}
                      >
                        <Box
                          component="img"
                          src={Purusha}
                          sx={{ width: "34px", height: "34px", pr: 1 }}
                        />
                        <Typography
                          sx={{ color: "white", display: "inline-block" }}
                        >
                          {agent.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
                      <Typography
                        key="created-on"
                        sx={{ color: "grey", fontSize: 14 }}
                      >
                        Created on
                      </Typography>
                      <Typography key="created-date" sx={{ color: "white" }}>
                        {getDate(agent.agent_created)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ color: "white", fontSize: 17 }}>
                        Generated
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}
    </Box>
  );
}
