import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  Typography,
  Input,
  Box,
  Button,
  FormGroup,
  Alert,
  Snackbar,
} from "@mui/material";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

import authContext from "../contexts/authContext";

import Nav from "../components/Nav/Nav";
import Orb from "../components/Orb";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [error, setError] = useState(false);
  const inputRef = useRef("");
  const navigate = useNavigate();

  const validateEmail = (email) => {
    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return true;
    } else {
      return false;
    }
  }

  const Login = (email, password) => {
    validateEmail(email) ? setEmailValid(true) : setEmailValid(false);
    authContext
      .login(email, password)
      .then((res) => {
        if (res) {
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        setError(true);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && email && password) {
      event.preventDefault();
      validateEmail(email) ? Login(email, password) : setEmailValid(false);
    }
  };

  useEffect(() => {
    if (authContext.isAuthenticated && authContext.user) navigate("/dashboard");
  }, [authContext]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={error}
        autoHideDuration={2000}
        onClose={() => setError(false)}
      >
        <Alert severity="error">Invalid email or password</Alert>
      </Snackbar>
      <Nav />
      <Box
        sx={{
          position: "absolute",
          left: "0px",
          top: "25%",
          right: "0px",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            m: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{ color: "white", fontSize: 32, fontWeight: 700, pb: 1.5 }}
          >
            Sign in to Purusha
          </Typography>
          <Typography sx={{ color: "white", fontSize: 17, fontWeight: 400 }}>
            You have been invited to Brand buddy. <br />
            Create an account to continue.
          </Typography>
          <FormGroup
            sx={{
              display: "flex",
              pt: 5,
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Input
              onChange={(e) => setEmail(e.target.value)}
              disableUnderline
              autoComplete="off"
              required
              type="email"
              name="email"
              placeholder="Email"
              onKeyDown={handleKeyDown}
              sx={{
                border: `1px solid ${emailValid ? "transparent" : "red"}`,
                backgroundColor: "rgba(231, 231, 231, 0.12)",
                p: 1.5,
                maxWidth: "420px",
                borderRadius: 25,
                color: "white",
                width: "100%",
              }}
            ></Input>
            <Input
              autoComplete="true"
              type="password"
              required
              onChange={(e) => setPassword(e.target.value)}
              disableUnderline
              placeholder="Password"
              onKeyDown={handleKeyDown}
              sx={{
                backgroundColor: "rgba(231, 231, 231, 0.12)",
                p: 1.5,
                width: "100%",
                maxWidth: "420px",
                borderRadius: 25,
                color: "white",
                mt: 2,
              }}
            ></Input>
            <Button
              disabled={email && password ? false : true}
              sx={{
                maxWidth: "fit-content",
                backgroundColor: "white",
                p: 2,
                mt: 2,
                borderRadius: 25,
                color: "black",
                opacity: email && password ? "100%" : "50%",
                "&:hover": {
                  background: "black",
                  ".MuiTypography-root": {
                    color: "white",
                  },
                },
              }}
              key="one"
              data="Login"
              onClick={() => Login(email, password)}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: "0.875rem",
                  fontWeight: 700,
                  alignContent: "center",
                  display: "contents",
                }}
              >
                Let's go <TrendingFlatIcon sx={{ mb: 0.5, ml: 1 }} />
              </Typography>
            </Button>
          </FormGroup>
        </Box>
      </Box>
      <Box sx={{ height: "1080px", opacity: 0.4 }}>
        <Orb
          prespectivePositionX={-0.0}
          prespectivePositionY={0.012}
          cameraPos={4}
          inputRef={inputRef}
        />
      </Box>
    </Box>
  );
}
