import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";

import AddIcon from "../../assets/icons/AddIcon.svg";
import SuccessIcon from "../../assets/icons/SuccessIcon.svg";
import Close from "../../assets/icons/CloseBlack.svg";

export default function ModelBox({ closeModelBox, personalities, personalitiesSelected }) {
  const [selected, setSelected] = useState(personalitiesSelected);

  const close = () => {
    closeModelBox("");
  };

  const handleSelected = (e) => {
    // Check if the item is already selected inside selected object array
    if (selected.filter((item) => item === e).length > 0) {
      // If selected remove it from the array
      setSelected(selected.filter((item) => item !== e));
    } else {
      // If not and less than 3 in selected add to the array
      if (selected.length <= 2) {
        setSelected((selected) => [...selected, e]);
      }
    }
  };

  const data = [
    { label: "Relaxed" },
    { label: "Kind" },
    { label: "Shy" },
    { label: "Fun" },
    { label: "Positive" },
    { label: "Serious" },
    { label: "Casual" },
    { label: "Funny" },
    { label: "Sarcastic" },
    { label: "Friendly" },
  ];


  useEffect(() => {
    personalities(selected)
  }, [selected]);


  return (
    <Box
      sx={{
        width: "fit-content",
        top: 230,
        left: 700,
        position: "absolute",
        p: 6.5,
        backgroundColor: "white",
        zIndex: 1,
        boxShadow: "0px 7px 34px rgba(0, 0, 0, 0.14)",
        borderRadius: "10px",
      }}
    >
      <Box
        component="img"
        onClick={() => close()}
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          maxWidth: "12px",
          maxHeight: "12px",
          cursor: "pointer",
        }}
        src={Close}
      />
      <Typography
        sx={{ color: "black", fontSize: "22px", maxWidth: "300px", pb: 4.75 }}
        variant="h4"
      >
        Next, what’s your bot’s personality?
      </Typography>
      <Typography sx={{ color: "grey", fontSize: "22px" }} variant="span">
        Choose 3 characteristics that match your brand.
      </Typography>
      <Box
        sx={{
          pt: 4.75,
          width: "428px",
          height: "300px",
          overflow: "hidden",
          overflowY: "scroll",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container rowSpacing={1}>
          {data.map((data, index) => (
            <Grid key={index} item xs={6}>
              <Box
                onClick={(e) => handleSelected(e.target.outerText)}
                id={index}
                sx={{
                  border: "2px solid black",
                  borderRadius: "8px",
                  width: "fit-content",
                  backgroundColor: selected.filter((item) => item === data.label).length > 0 ? "black" : "white",
                  p: "1.25rem",
                  cursor: 'pointer'
                }}
              >
                <Typography
                  id={index}
                  sx={{
                    fontWeight: "bold",
                    display: "flex",
                    color: selected.filter((item) => item === data.label).length > 0 ? "white" : "black",
                  }}
                >
                    <Box
                      component="img"
                      alt="info"
                      src={selected.filter((item) => item === data.label).length > 0 ? SuccessIcon : AddIcon}
                      sx={{ pr: 1, pointerEvents:'none' }}
                    />
                  {data.label}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
