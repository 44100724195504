import React, { useState, useEffect } from "react";
import { Box, Typography, Input } from "@mui/material";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";

import Info from "../../assets/icons/Info.svg";

import Tooltip from "../../components/Tooltip/Tooltip";

export default function Topic({ setTopic, topic, setFirstInteraction }) {
  const [topics, setTopics] = useState(topic ? topic : "");
  const [isTopic, setIsTopic] = useState(topic ? true : false);
  const [focus, setFocus] = useState(false);

  const [tooltip, setTooltip] = useState();

  const handleKeyDown = (e) => {
    if (e.key == "Enter") {
      setTopics(e.target.value);
      setIsTopic(topics.length === 0 ? false : true);
      setTopic(e.target.value);
    }
  };

  const handleChange = (e) => {
    setTopics(e.target.value);
    setIsTopic(topics.length === 0 ? false : true);
    setFocus(false);
  };

  const toolTip = (type) => {
    setTooltip(type);
  };

  useEffect(() => {
    setTopic(topics);
    setFirstInteraction(true);
  }, [topics]);

  useEffect(() => {
    if (topic) {
      if (topic.length === 0) {
        setIsTopic(false);
      }
    }
  }, [topics]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          fontSize: "1.5rem",
          marginBottom: "1.875rem",
          color: "var(--fontSecondaryColor)",
        }}
      >
        I'm here to help with{" "}
        {isTopic && (
          <Typography
            onClick={() => setIsTopic(false)}
            sx={{
              display: "inline-block",
              textDecoration: "underline",
              color: "var(--fontColor)",
              cursor: "pointer",
              fontSize: "1.5rem",
            }}
          >
            {topics}
          </Typography>
        )}
        {!isTopic && (
          <Input
            onKeyDown={(e) => handleKeyDown(e)}
            onFocus={() => setFocus(true)}
            onBlur={(e) => handleChange(e)}
            onChange={(e) => setTopics(e.target.value)}
            value={topics}
            placeholder="topics of conversation"
            endAdornment={
              focus ? (
                <SubdirectoryArrowLeftIcon
                  sx={{ cursor: "pointer", color: "white" }}
                  onClick={(e) => handleKeyDown(e)}
                />
              ) : (
                <ArrowRightIcon />
              )
            }
            sx={{
              "&.Mui-focused": { borderColor: "white" },
              border: "1px solid grey",
              borderRadius: "4px",
              color: "grey",
              fontSize: "20px",
              width: focus ? "260px" : "246px",
              height: 40,
              p: 0.5,
            }}
            disableUnderline={true}
          />
        )}
        {tooltip !== "topics" && (
          <Box
            component="img"
            onClick={() => toolTip("topics")}
            src={Info}
            sx={{
              height: "1.35rem",
              display: "inline-block",
              cursor: "pointer",
              verticalAlign: "middle",
              ml: 1,
            }}
            alt="info"
          />
        )}
      </Box>
      {tooltip == "topics" && (
        <Box sx={{ display: "inline-block", pl: 1.25 }}>
          <Tooltip
            title={
              "Tell us, what topics does your bot understand? For example:"
            }
            body={"Cooking, nutrition, recipes, lifestyle"}
            closeToolTip={toolTip}
          />
        </Box>
      )}
    </Box>
  );
}
