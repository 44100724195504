import React, { useEffect, useState } from "react";
import { Box, Typography, Input } from "@mui/material";
import Info from "../../assets/icons/Info.svg";
import Tooltip from "../../components/Tooltip/Tooltip";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";

export default function Purpose({ setPurpose, purpose, setFirstInteraction }) {
  const [tooltip, setTooltip] = useState();
  const [purposeCopy, setPurposeCopy] = useState(purpose ? purpose : "");
  const [focus, setFocus] = useState(false);
  const [openTextField, setOpenTextField] = useState(purpose ? false : true);

  const handleKeyDown = (e) => {
    if (e.key == "Enter") {
      setPurposeCopy(e.target.value);
      setOpenTextField(purpose.length === 0 ? true : false);
    }
  };

  const handleChange = (e) => {
    setPurposeCopy(e.target.value);
    setOpenTextField(purpose.length === 0 ? true : false);
    setFocus(false)
  };

  const toolTip = (type) => {
    setTooltip(type);
  };

  useEffect(() => {
    setPurpose(purposeCopy);
    setFirstInteraction(true);
  }, [purposeCopy]);

  useEffect(() => {
    if (purpose) {
      if (purpose.length === 0) {
        setOpenTextField(true);
      }
    }
  }, [purpose]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          fontSize: "1.5rem",
          marginBottom: "1.875rem",
          color: "var(--fontSecondaryColor)",
        }}
      >
        Your trusted companion for{" "}
        {!openTextField && (
          <Typography
            onClick={() => setOpenTextField(true)}
            sx={{
              fontSize: "1.5rem",
              color: "white",
              textDecoration: "underline",
              display: "inline-block",
            }}
          >
            {purposeCopy}
          </Typography>
        )}
        {openTextField && (
          <Input
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => setPurpose(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={(e) => handleChange(e)}
            value={purpose}
            placeholder="purpose"
            endAdornment={
              focus ? (
                <SubdirectoryArrowLeftIcon
                  sx={{ cursor: "pointer", color: "white" }}
                  onClick={(e) => handleKeyDown(e)}
                />
              ) : (
                <ArrowRightIcon />
              )
            }
            sx={{
              "&.Mui-focused": { borderColor: "white" },
              border: "1px solid grey",
              borderRadius: "4px",
              color: "grey",
              fontSize: "20px",
              width: focus ? "250px" : "113px",
              height: 40,
              p: 0.5,
            }}
            disableUnderline={true}
          />
        )}
        {tooltip !== "purpose" && (
          <Box
            component="img"
            onClick={() => toolTip("purpose")}
            sx={{
              height: "1.35rem",
              display: "inline-block",
              cursor: "pointer",
              verticalAlign: "middle",
              ml: 1,
            }}
            src={Info}
            alt="info"
          />
        )}
      </Box>
      {tooltip == "purpose" && (
        <Box sx={{ display: "inline-block", pl: 1.25 }}>
          <Tooltip
            closeToolTip={toolTip}
            title={"Tell us, what’s your bot’s purpose? For example:"}
            body={
              "Making language learning fun and easy. Improving home cook’s skills in the kitchen. Helping golfers improve their swing."
            }
          />
        </Box>
      )}
    </Box>
  );
}
