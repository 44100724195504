import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import Header from "./components/Header/Header";
import ChatBot from "./components/Chatbot/index";
import Builder from "./pages/Builder";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import AnonLogin from "./pages/AnonLogin";
import Playground from "./pages/Playground";
import Generate from "./pages/Generate";
import ChangePassword from "./pages/ChangePassword";

import authContext from "./contexts/authContext";
import { Box, Typography, Button } from "@mui/material";

import BasicModal from "./components/Modal/Modal";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function App() {
  authContext.init();
  const [modal, setModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const authPaths = () => {
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/generate"
    ) {
      return false;
    }
    return true;
  };

  const handleLogout = () => {
    setModal(false);
    authContext.logout();
    navigate("/login");
  };

  useEffect(() => {
    if (!authContext.user && !authContext.isAuthenticated) navigate("/login");
    if (window.location.pathname === "/") navigate("/dashboard");
  }, [authContext]);

  useEffect(() => {
    if (authPaths()) {
      let timer = setTimeout(() => {
        const session = JSON.parse(localStorage.getItem("AWSCognitoSession"));
        const milliseconds = (session.accessToken.payload.exp - Date.now() / 1000) * 1000;
        if (milliseconds < 1000 * 60 * 60) {
          setModal(true);
        }
      }, 1000 * 60 * 60);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [location]);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        className="app"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          height: "100%",
        }}
      >
        {authContext.isAuthenticated && authPaths() && (
          <>
            <Header
              user={authContext.user}
              url={window.location.pathname.split("/")[1]}
            />
            <BasicModal modal={modal}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography variant="h4" sx={{ pb: 3 }}>
                  Hey there!
                </Typography>
                <Typography variant="span" sx={{ pb: 3 }}>
                  Your session is about to expire
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    width: "100%",
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: "white",
                      ":hover": {
                        backgroundColor: "black",
                        ".MuiTypography-root": {
                          color: "white",
                        },
                      },
                      ".child": { fontColor: "white" },
                    }}
                    variant="contained"
                    onClick={() => navigate(0)}
                  >
                    <Typography variant="span">Stay connected</Typography>
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "white",
                      ":hover": {
                        backgroundColor: "black",
                        ".MuiTypography-root": {
                          color: "white",
                        },
                      },
                      ".child": { fontColor: "white" },
                    }}
                    variant="contained"
                    onClick={() => handleLogout()}
                  >
                    <Typography variant="span">Log out</Typography>
                  </Button>
                </Box>
              </Box>
            </BasicModal>
          </>
        )}
        <Routes>
          <Route exact path="/login" element={<Login />}></Route>
          <Route path="/invite" element={<AnonLogin />}></Route>
          {authContext.isAuthenticated && (
            <>
              <>
                <Route
                  path="/change-password"
                  element={<ChangePassword />}
                ></Route>
                <Route path="/onboarding" element={<Playground />}></Route>
                <Route path="/generate" element={<Generate />}></Route>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/builder" element={<Builder />} />
              </>
              <Route exact path="/chat_bot" element={<ChatBot />} />
            </>
          )}
        </Routes>
      </Box>
    </ThemeProvider>
  );
}
