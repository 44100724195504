import { Typography } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { generateSentences } from "../../../api/Api";
import { debounce } from "../../../helpers/debounce";

const GeneratedSentence = ({ sentence, onAdd }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h6">{sentence}</Typography>
      <Typography
        onClick={() => onAdd(sentence)}
        variant="body1"
        style={{
          cursor: "pointer",
        }}
      >
        + add
      </Typography>
    </div>
  );
};

export const TovGenerator = ({ seed, handleSelected, mode }) => {
  const [loadingSuggested, isLoadingSuggested] = useState(false);
  const [suggestedSentences, setSuggestedSentences] = useState([]);

  useEffect(() => {
    seed && seed.length > 1
      ? loadNewSuggestions(seed)
      : setSuggestedSentences([]);
  }, [seed]);

  const generateSentence = async (value) => {
    isLoadingSuggested(true);
    setSuggestedSentences([]);
    const values = [];
    const response = await generateSentences(localStorage.getItem("agent_id"), {
      query: value,
      mode: mode,
    });

    response.data
      .split("\n")
      ?.slice(0, 3)
      .map((value) => {
        values.push(
          value.indexOf("-") > -1 ? value.slice(value.indexOf("-") + 1) : value,
        );
      });

    setSuggestedSentences(values);
    isLoadingSuggested(false);
  };

  const loadNewSuggestions = useCallback(
    debounce((sentence) => {
      generateSentence(sentence);
    }, 400),
    [],
  );

  return (
    <>
      <Typography
        variant="body1"
        style={{
          color: "#767676",
          marginBottom: "24px",
        }}
      >
        SUGGESTED PHRASES
      </Typography>
      <div>
        {loadingSuggested ? (
          <p>loading</p>
        ) : (
          suggestedSentences.map((sentence) => (
            <GeneratedSentence
              sentence={sentence}
              onAdd={handleSelected}
              key={sentence}
            />
          ))
        )}
      </div>
    </>
  );
};
