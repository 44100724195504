import React, { useEffect, useState, useSearchParams } from "react";
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';

import { CircularProgress } from "@mui/material";

export default function AnonLogin() {
  const queryParams = new URLSearchParams(window.location.search);
  const [agentId, setAgentId] = useState(queryParams.get('agent_id'));
  const [apiRoot, setApiRoot] = useState(queryParams.get('api_root'));
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      return;
    } else {
      localStorage.setItem("agent_id", agentId);
      localStorage.setItem("api_root", apiRoot);
      anonSignIn(agentId, apiRoot)
      navigate("/chat_bot");
    }
 
  }, [loading]);
  return (
    <Box sx={styled.container}>
      <Box sx={styled.login}>
        <Box sx={styled.login__container}>
          <CircularProgress />
        </Box>
      </Box>
    </Box>
  );
}

const styled = {
  container: {
    backgroundImage: 'url(assets/login-hero.png)',
    maxWidth: '100%',
    backgroundPosition: 'bottom'
  },
  logo: {
    backgroundImage: 'url(assets/LOGO1.png)',
    height: '50px',
    width: '50px',
  },
  login: {
    height: '100vh',
    display: 'flex',
    maxWidth: '500px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'left'
  },
  login__container: {
    minWidth: '90%',
    display: 'flex',
    flexDirection: 'column',
    padding: '30px'
  },
  login__textBox: {
    minHeight: '50px',
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '5px',
    fontSize: '18px',
    marginBottom: '10px'
  },
  login__button: {
    minWidth: '250px',
    borderRadius: '5px',
    backgroundColor: 'white',
    color: 'black',
    marginBottom: 2,
    maxWidth: 20,
    ":hover": {
      backgroundColor: 'gray',
      border: 'none',
    }
  }
}