import React, { Suspense, useMemo, useRef, memo } from "react"
import { MathUtils } from "three"
import { Canvas, useFrame } from "@react-three/fiber"
import {
  Sphere,
  PerspectiveCamera,
  Environment,
  ContactShadows,
} from "@react-three/drei"
import { Depth, Displace, LayerMaterial } from "lamina"

function Three3JSCanvas({ cameraPos, prespectivePositionX, prespectivePositionY, inputRef }) {
  const focalLength = 4 // originally 10, 10 - 50
  const cameraPosition = cameraPos // originally 6

  return (
    <Canvas dpr={[1, 2]}>
      <PerspectiveCamera makeDefault position={[prespectivePositionX, prespectivePositionY, cameraPosition]} fov={focalLength}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} intensity={2} />
      </PerspectiveCamera>

      <Suspense fallback={null}>
        <Orb position={[0, 0, 0]} inputRef={inputRef} />

        <Environment preset="studio" />

        <ContactShadows
          rotation={[Math.PI / 2, 0, 0]}
          position={[0, -1.6, 0]}
          opacity={0.8}
          width={15}
          height={15}
          blur={2.5}
          far={1.6}
        />
      </Suspense>
    </Canvas>
  )
}

function Orb({ inputRef }) {
  // component refs
  const sphereRef = useRef()
  const textureRef = useRef()
  const displaceRef = useRef()
  const prevInputRef = useRef(inputRef.current.value)

  // state refs
  const speed = useRef(0.05) // speed of ripple | good range: 0.0 - 0.8
  const scale = useRef(5) // surface area of ripple | 2 - 15
  const strength = useRef(0.25) // height of ripple | 0.1 - 1
  const roughness = useRef(1) // roughness of blob texture | 0.1 - 1
  const gradient = 0.5
  const rand = useMemo(() => Math.random(), [])

  // animation
  useFrame(({ clock }, delta) => {
    // orb bounce
    sphereRef.current.position.y =
      Math.sin(clock.elapsedTime + rand * 100) * 0.025 - 0.05

    // orb react on text input
    if (inputRef.current.value !== prevInputRef.current) {
      displaceRef.current.strength = MathUtils.lerp(
        displaceRef.current.strength,
        strength.current * 1.15,
        0.3
      )

      prevInputRef.current = inputRef.current.value

      // TODO: Replace above ripple w/ smooth x & y rotation
    }

    // transitions wobble size
    if (displaceRef.current.strength !== strength.current) {
      displaceRef.current.strength = MathUtils.lerp(
        displaceRef.current.strength,
        strength.current,
        1
      )
    }

    // animates wobble
    if (strength.current > 0)
      displaceRef.current.offset.x += speed.current * delta

    // layer gradient movement
    const sin = Math.sin(clock.elapsedTime / 2)
    const cos = Math.cos(clock.elapsedTime / 2)
    textureRef.current.layers[0].origin.set(cos / 2, 0, 0)
    textureRef.current.layers[1].origin.set(cos, sin, cos)
    textureRef.current.layers[2].origin.set(sin, cos, sin)
    textureRef.current.layers[3].origin.set(cos, sin, cos)
  })

  return (
    <group position={[0, 0, -10]}>
      <Sphere
        castShadow
        ref={sphereRef}
        args={[0.4, 128, 128]}
        rotationX={Math.PI * 0.25} rotationY={Math.PI * 0.25}
      >
        <LayerMaterial
          ref={textureRef}
          lighting={"physical"}
          roughness={roughness.current}
          transmission={0.1}
          thickness={2}
        >
          <Depth
            colorA="#ff0080"
            colorB="black"
            alpha={1}
            mode="normal"
            near={0.5 * gradient}
            far={0.5}
            origin={[0, 0, 0]}
          />

          <Depth
            colorA="#004cff"
            colorB="#f7b955"
            alpha={1}
            mode="add"
            near={2 * gradient}
            far={2}
            origin={[0, 1, 1]}
          />

          <Depth
            colorA="green"
            colorB="#fff200"
            alpha={1}
            mode="add"
            near={3 * gradient}
            far={3}
            origin={[0, 1, -1]}
          />

          <Depth
            colorA="black"
            colorB="red"
            alpha={1}
            mode="overlay"
            near={1.5 * gradient}
            far={1.5}
            origin={[1, -1, -1]}
          />

          <Displace
            ref={displaceRef}
            scale={scale.current} // surface area of individual ripple
            offset={[0, 0, 0]}
          />
        </LayerMaterial>
      </Sphere>
    </group>
  )
}

export default memo(Three3JSCanvas)
