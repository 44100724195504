import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  useMediaQuery,
  Alert,
  Snackbar,
  Box,
  Tabs,
  Tab,
  Tooltip,
  Button,
  Avatar,
  Typography,
  Menu,
  Divider,
} from "@mui/material";

import Purusha from "../../assets/icons/Purusha.svg";
import UserWhite from "../../assets/icons/UserWhite.svg";
import UserBlack from "../../assets/icons/UserBlack.svg";
import Share from "../../assets/icons/Share.svg";
import Close from "../../assets/icons/CloseWhite.svg";
import authContext from "../../contexts/authContext";

export default function Header({ url, user }) {
  const matches = useMediaQuery("(max-width:900px)");
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [menuActive, setMenuActive] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    setMenuActive(true);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setMenuActive(false);
  };

  const handleLogout = () => {
    authContext.logout();
    navigate("/login");
  };

  const handlePasswordChange = () => {
    navigate("/change-password")
  };

  const handleNavigate = (value) => {
    const agentId = localStorage.getItem("agent_id");
    if (agentId || url === "change-password" || url === "onboarding") {
      navigate(value);
    }
  };

  const copyLinkToKeyboard = () => {
    setShowSnackbar(true);
    navigator.clipboard.writeText(
      `${window.location.origin}/invite?agent_id=${localStorage.getItem(
        "agent_id"
      )}&api_root=${localStorage.getItem("api_root")}`
    );
  };

  return (
    <Box
      sx={{
        zIndex: 10,
        borderRight: matches ? "none" : "1px solid white",
        borderBottom: matches ? "1px solid white" : "none",
        height: "100%",
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: 'column' }}>
        {data?.map((item, index) => (
          <Box key={index}>
            <Box
              onClick={() => handleNavigate(item.value)}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                opacity: url == item.value ? 1 : 0.5,
                pt: 3,
                visibility:
                  item.value == "dashboard" || localStorage.getItem("agent_id")
                    ? "visible"
                    : "hidden",
              }}
            >
              <Box
                component="img"
                src={Purusha}
                alt="purusha"
                sx={{ height: "33px", width: "33px", cursor: "pointer" }}
              />
              <Tab
                sx={{
                  margin: 1,
                  color: "white",
                  opacity: 1,
                }}
                disableRipple
                key={index}
                label={item.title}
              />
              {index !== data.length - 1 && (
                <Divider
                  sx={{
                    background: "#767676",
                    width: "17px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  variant="middle"
                  orientation="horizontal"
                />
              )}
            </Box>
          </Box>
        ))}
      </Box>
      {!user.isAnonymous && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            onClick={handleOpenUserMenu}
            key="user"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '32px',
              width: '100%',
              borderTop: '1px solid lightgray',
              cursor: 'pointer',
              backgroundColor: menuActive ? "white" : "transparent",
              "&:hover": {
                background: "#424242"
              },
            }}
          >
            <Tooltip title="Open settings">
              <Avatar
                alt="Avatar"
                sx={{ width: "20px", height: "20px" }}
                src={menuActive ? UserBlack : UserWhite}
              />
            </Tooltip>
          </Box>
          <Box
            onClick={copyLinkToKeyboard}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '32px',
              width: '100%',
              borderTop: '1px solid lightgray',
              cursor: 'pointer',
              "&:hover": {
                background: "#424242"
              },
            }}
          >
            <img
              alt="Copy Link"
              src={Share}
            />
          </Box>
        </Box>
      )}

      <Menu
        sx={{
          ml: 10,
          mt: -9,
          ".MuiMenu-paper": {
            backgroundColor: "#E7E7E733",
            backdropFilter: "blur(25px)",
            pb: 0,
          },
          ".MuiList-root": { p: 0 },
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Box sx={{ p: 2 }}>
          <Box>
            <Box
              component="img"
              onClick={() => handleCloseUserMenu()}
              src={Close}
              sx={{ position: "absolute", top: '12px', right: '12px', p: 1, cursor: 'pointer' }}
            />
            <Typography sx={{ fontSize: 20, fontWeight: 700, pb: 1 }}>
              My account
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 500, color: "grey" }}>
              Signed in as
            </Typography>
            <Typography sx={{ fontSize: 17, fontWeight: 500, pb: 4 }}>
              {user.username}
            </Typography>
          </Box>
          <Box onClick={handleCloseUserMenu}>
            <Button
              variant="outlined"
              onClick={() => handleLogout()}
              sx={{
                marginRight: '12px'
              }}>
              Logout
            </Button>
            <Button variant="text" onClick={() => handlePasswordChange()}>
              Reset Password
            </Button>
          </Box>
        </Box>
      </Menu>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert severity="success">Bot link copied to clipboard!</Alert>
      </Snackbar>
    </Box>
  );
}

const data = [
  {
    title: "Home",
    value: "dashboard",
  },
  {
    title: "Buddy",
    value: "chat_bot",
  },
  {
    title: "Intents",
    value: "builder",
  },
];
