import React, { useRef, useState, useEffect, useCallback } from "react";
import { Slide, Snackbar, Box, useMediaQuery } from "@mui/material";
import { debounce } from "../helpers/debounce";

import { Alert } from "@mui/material";
import IntentsOverview from "../components/Builder/IntentsOverview";
import Editor from "../components/Builder/Editor";

import { updateIntentItem, getIntents, trainModel, getAgent } from "../api/Api";

export default function Builder() {
  const [showInProgress, setShowInProgress] = useState(false);
  const [showComplete, setShowComplete] = useState(false);
  const [data, setData] = useState([]);
  const [utterance, setUtterance] = useState([]);
  const [response, setResponse] = useState("");
  const [intentIndex, setIntentIndex] = useState(0);
  const [currentIntent, setCurrentIntent] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [agentData, setAgentData] = useState();
  const [message, setMessage] = useState("");
  const [firstInteraction, setFirstInteraction] = useState(false);
  const containerRef = useRef(null);
  const matches = useMediaQuery("(max-width:900px)");

  useEffect(() => {
    getAgent(localStorage.getItem("agent_id")).then((data) => {
      setAgentData(data);
      setIsLoading(false);
    });
  }, []);

  const getIntent = (intent, index) => {
    setIntentIndex(index);
    setCurrentIntent(intent);
    setResponse(data[intent].responses);
    setUtterance(data[intent].utterances);
  };

  const getRemoveIntent = (value) => {
    setFirstInteraction(true)
    const currentIntents = [{ ...data }];
    delete currentIntents[0][value];
    setData(currentIntents[0]);
  };

  const getCreateIntent = async (value) => {
    setFirstInteraction(true);
    const currentIntents = [{ ...data }];
    const newIntent = {
      [value]: {
        utterances: [],
        responses: [],
      },
    };
    currentIntents.push(newIntent);
    let merged = { ...currentIntents[0], ...currentIntents[1] };
    setData(merged);
  };

  const updateExamples = (value, type) => {
    setFirstInteraction(true);
    type === "utterances" ? setUtterance(value) : setResponse(value);
    setData({
      ...data,
      [currentIntent]: { ...data[currentIntent], [type]: value },
    });
  };

  const submitData = async () => {
    setMessage("Training in progress...");
    setShowInProgress(true);
    await trainModel(agentData[0].intents_id);
    setShowInProgress(false);
    setMessage("Training complete");
    setShowComplete(true);
  };
  useEffect(() => {
    if (!isLoading) {
      getIntents(agentData[0].intents_id).then((result) => {
        setData(result.intent_data);
      });
    }
  }, [isLoading]);

  const debouncedUpdate = useCallback(
    debounce((value, agent_data) => {
      updateIntentItem(agent_data.intents_id, value);
      setMessage("Saving complete");
      setShowInProgress(false);
      setShowComplete(true);
    }, 2500),
    []
  );

  useEffect(() => {
    if (agentData && firstInteraction) {
      setShowInProgress(true);
      setMessage("Saving in progress...");
      debouncedUpdate(data, agentData[0]);
    }
  }, [data]);

  return (
    <Box sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        key="info"
        open={showInProgress}
        onClose={() => setShowInProgress(false)}
      >
        <Alert severity="info">{message}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        key="success"
        open={showComplete}
        autoHideDuration={2000}
        onClose={() => setShowComplete(false)}
      >
        <Alert severity="success">{message}</Alert>
      </Snackbar>
      <Box ref={containerRef}>
        {!isLoading && (
          <Slide direction="up" in={true} container={containerRef.current}>
            <div
              style={
                matches
                  ? styled.intentManagerContainerMobile
                  : styled.intentManagerContainerDesktop
              }
            >
              <div
                key="intents"
                style={{
                  background: "black",
                  padding: "32px 16px",
                }}
              >
                <IntentsOverview
                  retrainModel={submitData}
                  data={data}
                  selectedIntentIndex={intentIndex}
                  setIntent={getIntent}
                  createIntent={getCreateIntent}
                  removeIntent={getRemoveIntent}
                />
              </div>
              <div
                key="utterances"
                style={{
                  background: "black",
                  padding: "32px 16px",
                }}
              >
                <Editor
                  header="When a user says"
                  subheader="If they say phrases similar to these ..."
                  mode="utterances"
                  updateExamples={updateExamples}
                  examples={utterance}
                  intentIndex={intentIndex}
                />
              </div>
              <div
                key="responses"
                style={{
                  background: "black",
                  padding: "32px 16px",
                }}
              >
                <Editor
                  header="Buddy responds"
                  subheader="Your bot will reply randomly with one of these"
                  mode="responses"
                  updateExamples={updateExamples}
                  examples={response}
                  intentIndex={intentIndex}
                />
              </div>
            </div>
          </Slide>
        )}
      </Box>
    </Box>
  );
}

const styled = {
  intentManagerContainerDesktop: {
    height: "100vh",
    display: "grid",
    gridAutoFlow: "column",
    gap: "1px",
    background: "white",
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  intentManagerContainerMobile: {
    height: "100vh",
    display: "grid",
    gridAutoFlow: "column",
    gap: "1px",
    background: "white",
    gridTemplateRows: "1fr 1fr 1fr",
  },
};
