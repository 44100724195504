import React from "react"
import WhatsApp from '../../assets/icons/WhatsApp.png'
import Instagram from '../../assets/icons/Instagram.png'
import Purusha from '../../assets/icons/Purusha.svg'
import Messenger from '../../assets/icons/Messenger.svg'

import styles from "../../pages/Playground/style.module.css"

export default function Nav() {

  return (
      <nav className={styles.nav}>
        <img src={Purusha} className={styles.purusha}/>
        <h3>Purusha</h3>
        <div className={styles.logoContainer}>
          <div className={styles.logos}>
            <img src={Instagram} className={styles.instagram} />
            <img src={WhatsApp} className={styles.whatsapp} />
            <img src={Messenger} className={styles.messenger} />
          </div>
        </div>
      </nav>
  )
}
