import React, { useRef, useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Accordion from "../components/Accordion/Accordion";

import { scanAgents } from "../api/Api";

export default function Builder() {
  const [agents, setAgents] = useState([]);
  const navigate = useNavigate();

  const containerRef = useRef(null);



  useEffect(() => {
    scanAgents({ TableName: "agents" }).then((data) => {
      data.sort((a, b) => {
        return new Date(b.agent_created) - new Date(a.agent_created);
      });
      setAgents(data)
    });
  }, []);
  return (
    <Box ref={containerRef} sx={{width: '100%'}}>
      <Slide direction="up" in={true} container={containerRef.current}>
        <Container fixed maxWidth="xl" sx={styled.container}>
          <Card sx={styled.cards}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">Agents</Typography>
              <Button
                color="inherit"
                variant="outlined"
                onClick={() => navigate("/onboarding")}
              >
                + Create agent
              </Button>
            </Box>
            <Divider sx={{ margin: 2 }} variant="middle"></Divider>
            <Accordion agents={agents} />
          </Card>
        </Container>
      </Slide>
    </Box>
  );
}

const styled = {
  container: {
    height: "90vh",
    paddingTop: "32px",
    paddingBottom: "32px",
    display: "flex",
    flexDirection: "row",
  },
  cards: {
    margin: 2,
    display: "flex",
    height: "100%",
    boxSizing: "border-box",
    flexDirection: "column",
    padding: "18px 12px",
    flex: 1,
    overflow: "scroll",
  },
};
