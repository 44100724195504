import React, { useState, useEffect } from "react";
import { TextField, Typography } from "@mui/material";
import { ViewEditExample } from "./ViewEditExample";
import { TovGenerator } from "./TovGenerator";

export default function Editor({
  header,
  subheader,
  mode,
  examples,
  addNewExample,
  updateExamples,
  intentIndex,
}) {
  const [newSentence, setNewSentence] = useState("");
  const [latestExamples, setLatestExamples] = useState(examples);

  useEffect(() => {
    setLatestExamples(examples);
  }, [examples]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSave();
      setNewSentence("");
      updateExamples([...examples, newSentence], mode);
    }
  };

  const handleSave = () => {
    setNewSentence("");
    updateExamples([...examples, newSentence], mode);
  };

  const handleGeneratedSave = (generatedSentence) => {
    updateExamples([...examples, generatedSentence], mode);
  };

  const handleDelete = (i) => {
    const updatedList = [...examples];
    updatedList.splice(i, 1);
    updateExamples(updatedList, mode);
  };

  const handleEdit = (i, updatedValue) => {
    const updatedList = [...examples];
    updatedList[i] = updatedValue;
    updateExamples(updatedList, mode);
  };

  useEffect(() => {
    if (intentIndex !== undefined) {
      setNewSentence("");
    }
  }, [intentIndex]);

  return (
    <div
      style={{
        padding: "8px 0px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <div>
        <div>
          <Typography
            variant="h4"
            style={{
              marginBottom: "24px",
            }}
          >
            {header}
          </Typography>
          <Typography
            variant="body1"
            style={{
              color: "#767676",
            }}
          >
            {subheader}
          </Typography>

          <TextField
            onChange={(e) => setNewSentence(e.target.value)}
            value={newSentence}
            onKeyDown={handleKeyDown}
            margin="normal"
            placeholder="Type in a new phrase..."
            id="outlined-basic"
            sx={{
              width: "100%",
              background: "#2B2B2B",
              borderRadius: "18px",
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'none',
                  borderRadius: '18px',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },
            }}
          />
        </div>
        <div>
          {latestExamples &&
            latestExamples.map((utter, i) => (
              <ViewEditExample
                key={i}
                index={i}
                sentence={utter}
                onUpdate={handleEdit}
                onDelete={handleDelete}
              />
            ))}
        </div>
      </div>
      <div>
        <TovGenerator
          mode={mode}
          seed={newSentence}
          handleSelected={handleGeneratedSave}
        />
      </div>
    </div>
  );
}
