import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  Typography,
  Input,
  Box,
  Button,
  FormGroup,
  Alert,
  Snackbar,
} from "@mui/material";

import authContext from "../contexts/authContext";
import Orb from "../components/Orb";

export default function ChangePassword() {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [button, setButton] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef("");
  const navigate = useNavigate();

  const changePassword = async () => {
    if (newPassword === confirmPassword) {
      authContext
        .changePassword(password, newPassword)
        .then((data) => {
          navigate("/dashboard");
        })
        .catch((err) => {
          setErrorMessage(String(err).split(":")[1]);
          setShowError(true);
        });
    } else {
      setErrorMessage("Passwords do not match");
      setShowError(true);
    }
    return false;
  };

  useEffect(() => {
    if (password !== "" && newPassword !== "" && confirmPassword !== "") {
      setButton(false);
    } else {
      setButton(true);
    }
  }, [password, newPassword, confirmPassword]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        key="error"
        open={showError}
        autoHideDuration={2000}
        onClose={() => setShowError(false)}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <Box
        sx={{
          position: "absolute",
          left: "0px",
          top: "25%",
          right: "0px",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            m: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography sx={{ color: "white", fontSize: 17, fontWeight: 400 }}>
            Please reset your password to continue
          </Typography>
          <FormGroup sx={{ pt: 5 }}>
            <Input
              autoComplete="true"
              type="password"
              required
              onChange={(e) => setPassword(e.target.value)}
              disableUnderline
              placeholder="Current password"
              sx={{
                backgroundColor: "rgba(231, 231, 231, 0.12)",
                p: 1.5,
                width: "420px",
                borderRadius: 25,
                color: "white",
                mt: 2,
              }}
            ></Input>
            <Input
              autoComplete="true"
              type="password"
              required
              onChange={(e) => setNewPassword(e.target.value)}
              disableUnderline
              placeholder="New password"
              sx={{
                backgroundColor: "rgba(231, 231, 231, 0.12)",
                p: 1.5,
                width: "420px",
                borderRadius: 25,
                color: "white",
                mt: 2,
              }}
            ></Input>
            <Input
              autoComplete="true"
              type="password"
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
              disableUnderline
              placeholder="Confirm new password"
              sx={{
                backgroundColor: "rgba(231, 231, 231, 0.12)",
                p: 1.5,
                width: "420px",
                borderRadius: 25,
                color: "white",
                mt: 2,
              }}
            ></Input>
          </FormGroup>
          <Button
            disabled={button}
            sx={{
              maxWidth: "fit-content",
              backgroundColor: "white",
              p: 2,
              mt: 2,
              borderRadius: 25,
              color: "black",
              opacity: button ? "50%" : "100%",
              "&:hover": {
                background: "black",
                ".MuiTypography-root": {
                  color: "white",
                },
              },
            }}
            key="one"
            data="Reset Password"
            onClick={() => changePassword()}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: "0.875rem",
                fontWeight: 700,
                alignContent: "center",
                display: "contents",
              }}
            >
              Reset Password
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: "100%", opacity: 0.4 }}>
        <Orb
          prespectivePositionX={-0.0}
          prespectivePositionY={0.012}
          cameraPos={4}
          inputRef={inputRef}
        />
      </Box>
    </Box>
  );
}
