import React, { useState, useEffect } from "react";
import { Box, Typography, Input, MenuItem } from "@mui/material";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";

export default function Buddy({ setBuddy, buddy, setFirstInteraction }) {
  const [archetypeName, setArchetypeName] = useState(buddy ? buddy : "");
  const [focus, setFocus] = useState(false);
  const [archetypeNameActive, setArchetypeNameActive] = useState(
    buddy ? false : true
  );

  const handleArchetypeName = (event) => {
    if (event?.key === "Enter" || typeof event?.key === "undefined") {
      setArchetypeName(
        event.target.value ? event.target.value : event.target.outerText
      );
      setFocus(false);
      setArchetypeNameActive(false);
    }
  };

  useEffect(() => {
    setBuddy(archetypeName);
    setFirstInteraction(true);
  }, [archetypeName]);

  useEffect(() => {
      if (buddy?.length === 0) {
        setArchetypeNameActive(true);
    }
  }, [buddy]);

  return (
    <Box>
      <Box
        sx={{
          fontSize: "1.5rem",
          marginBottom: "1.875rem",
          color: "var(--fontSecondaryColor)",
        }}
      >
        I'm{" "}
        {archetypeNameActive || buddy.length === 0 ? (
          <Input
            onKeyDown={(e) => handleArchetypeName(e)}
            onFocus={() => setFocus(true)}
            // onBlur={(e) => handleChange(e)}
            onChange={(e) => setArchetypeName(e.target.value)}
            value={archetypeName}
            placeholder="name"
            endAdornment={
              focus ? (
                <SubdirectoryArrowLeftIcon
                  sx={{ cursor: "pointer", color: "white" }}
                  onClick={(e) => handleKeyDown(e)}
                />
              ) : (
                <ArrowRightIcon />
              )
            }
            sx={{
              "&.Mui-focused": { borderColor: "white" },
              border: "1px solid grey",
              borderRadius: "4px",
              color: "grey",
              fontSize: "20px",
              width: focus ? "250px" : "86px",
              height: 40,
              p: 0.5,
            }}
            disableUnderline={true}
          />
        ) : (
          <Typography
            sx={{
              display: "inline-block",
              textDecoration: "underline",
              fontSize: "1.5rem",
            }}
            onClick={() => setArchetypeNameActive(true)}
          >
            {archetypeName}
          </Typography>
        )}{" "}
        buddy
        {focus && (
          <Box
            sx={{
              position: "absolute",
              backgroundColor: "grey",
              color: "white",
              width: "256px",
              marginLeft: 5,
              zIndex: 1,
            }}
          >
            <Typography
              sx={{
                textTransform: "uppercase",
                ml: 2,
                fontSize: 12,
                mt: 1,
                opacity: "0.5",
              }}
            >
              EXPLORE PREMADE BOTS
            </Typography>
            <MenuItem onClick={(e) => handleArchetypeName(e)}>
              Chef Academy Co
            </MenuItem>
            <MenuItem onClick={(e) => handleArchetypeName(e)}>
              Boot Camp Co
            </MenuItem>
            <MenuItem onClick={(e) => handleArchetypeName(e)}>
              Mindful Dojo
            </MenuItem>
          </Box>
        )}
      </Box>
    </Box>
  );
}
