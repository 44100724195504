import React, { useEffect } from 'react';
import {Typography, Fab} from '@mui/material';
import { CreateNewIntentButton } from './Editor/CreateIntentButton';
import Delete from '../../assets/icons/Delete.svg'

export default function IntentsOverview({ data, setIntent, selectedIntentIndex, createIntent, removeIntent, retrainModel }) {
    useEffect(() => {
        if (Object.keys(data).length > 0 && (selectedIntentIndex === 0 || selectedIntentIndex === undefined)) {
            setIntent(Object.keys(data)[0], 0);
        }
    }, [data]);
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between'
        }}>
            <div>
                <Typography variant="h4" style={{
                    paddingLeft: '16px',
                }}>Intents</Typography>
                <div style={{
                    marginTop: '32px'
                }}>
                    {
                        Object.keys(data).map((intent, i) =>
                            <div
                                key={i}
                                onClick={() => setIntent(intent, i)}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    cursor: 'pointer',
                                    marginBottom: '18px',
                                    padding: '12px 16px',
                                    borderRadius: '6px',
                                    background: i === selectedIntentIndex ? 'white' : 'black'
                                }}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: i === selectedIntentIndex ? 'black' : 'white',
                                        fontWeight: '700'
                                    }}
                                >
                                    {intent}
                                </Typography>
                                <img src={Delete} onClick={() => removeIntent(intent)} />
                            </div>)
                    }
                </div>
            </div>
            <div style={{
                display: 'grid',
                gridGap: '4px',
                gridAutoFlow: 'column'
            }}>
                <CreateNewIntentButton onSave={(newIntent) => createIntent(newIntent)}/>
                <Fab 
                    onClick={() => retrainModel()} 
                    size="large" 
                    variant="extended"
                    style={{
                        color: 'white',
                        background: 'black',
                        border: '1px solid white'
                    }}
                >
                    Retrain
                </Fab>
            </div>
        </div>
    )
}