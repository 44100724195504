import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'rgba(231, 231, 231, 0.2)',
  borderRadius: 5,
  p: 4,
  backdropFilter: "blur(25px)",
};

export default function BasicModal({modal, handleCloseModal, ...props}) {
  return (
    <div>
      <Modal
        open={modal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            {props.children}
        </Box>
      </Modal>
    </div>
  );
}