import React from 'react';
import { Box, Typography } from '@mui/material';
import Close from '../../assets/icons/CloseWhite.svg'

export default function Tooltip({ closeToolTip, title, body}) {
    const close = () =>{
        closeToolTip('')
    }
    return (
      <Box sx={styled.container}>
          <Box component="img" onClick={() => close()} sx={styled.close} src={Close} />
         <Typography id="title" sx={styled.title} variant="h4">{title}</Typography>
         <Typography id="body" sx={styled.body} variant="p">{body}</Typography>
      </Box>
    );
}

const styled = {
  container: {
    position: 'absolute',
    backgroundColor: '#e7e7e733',
    backdropFilter: 'blur(25px)',
    borderRadius: 1.5,
    padding: 3,
    maxWidth: '300px'
  },
  title: {
    color: '#FFFFFF',
    fontSize: 18,
    pb: 2.25
  },
  body: {
    color: '#FFFFFF',
    fontSize: 16
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 10,
    maxWidth: '12px',
    maxHeight: '12px',
    cursor: 'pointer'
  }
}