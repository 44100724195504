import React, { useRef, useState, useEffect, useCallback } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Typography, Box, Snackbar, Alert } from "@mui/material";

import Buddy from "./Buddy";
import Purpose from "./Purpose";
import Personality from "./Personality";
import Topic from "./Topic";
import { debounce } from "../../helpers/debounce";

import { Messages, updateAgentItem, getAgent } from "../../api/Api";

import Orb from "../../components/Orb";

import styles from "../../pages/Playground/style.module.css";

export default function ChatBot() {
  const inputRef = useRef("");
  const [agentData, setAgentData] = useState();
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([{}]);
  const [buddy, setBuddy] = useState();
  const [purpose, setPurpose] = useState();
  const [topic, setTopic] = useState();
  const [messageSubmission, setMessageSubmission] = useState(false);
  const [personalities, setPersonalities] = useState();
  const [loading, setLoading] = useState(true);
  const [showInProgress, setShowInProgress] = useState(false);
  const [showComplete, setShowComplete] = useState(false);
  const [infoMessage, setInfoMessage] = useState(false);
  const [firstInteraction, setFirstInteraction] = useState(false);

  useEffect(() => {
    getAgent(localStorage.getItem("agent_id")).then((data) => {
      setAgentData(data);
      setBuddy(data[0].name);
      setPurpose(data[0].purpose);
      setTopic(data[0].topic);
      setPersonalities(data[0].personalities);
      setLoading(false);
    });
  }, []);

  const myRef = useRef(null);

  var postData = {
    query: userInput,
    convo_log: "",
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submitMessage();
    }
  };

  const updateValues = async (buddy, purpose, topic, personalities) => {
    updateAgentItem(localStorage.getItem("agent_id"), {
      agent: {
        name: buddy,
        purpose: purpose,
        topic: topic,
        personalities: personalities,
      },
    })
  };

  const submitMessage = () => {
    setMessages([...messages, { message: userInput, userMessage: true }]);
    setMessageSubmission(true);
  };

  const fetchData = async () => {
    const response = await Messages(
      agentData[0].agent_id,
      agentData[0].intents_id,
      postData
    );
    if (response.data.source === "gpt3") {
      var data = response.data.response;
    } else {
      var data =
        response.data.response?.indexOf("<0>") > -1
          ? response.data.response?.split("<0>")[
              Math.floor(
                Math.random() * response.data.response?.split("<0>").length
              )
            ]
          : response.data.response;
    }
    setMessages([
      ...messages,
      { message: data, userMessage: false, meta: response.data },
    ]);
    setMessageSubmission(false);
  };

  useEffect(() => {
    if (myRef) {
      myRef.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
    if (messageSubmission) {
      fetchData(userInput);
      setUserInput("");
    }
  }, [messageSubmission]);


  const debouncedUpdate = useCallback(
    debounce((buddy, purpose, topic, personalities) => {
      updateValues(buddy, purpose, topic, personalities);
      setInfoMessage("Saving complete")
      setShowInProgress(false);
      setShowComplete(true);
    }, 2500),
    []
  );

  useEffect(() => {
    if (firstInteraction) {
        setShowInProgress(true);
        setInfoMessage("Saving agent data...")
        debouncedUpdate(buddy, purpose, topic, personalities);
    }
  }, [buddy, purpose, topic, personalities]);

  return (
    <div className={styles.container}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        key="info"
        open={showInProgress}
        onClose={() => setShowInProgress(false)}
      >
        <Alert severity="info">{infoMessage}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        key="success"
        open={showComplete}
        autoHideDuration={2000}
        onClose={() => setShowComplete(false)}
      >
        <Alert severity="success">{infoMessage}</Alert>
      </Snackbar>
      <Box
        sx={{
          left: 0,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
          overflowY: "hidden",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            height: "100%",
            zIndex: 2,
            height: "100%",
            padding: "0 5% 0 7%",
            display: "flex",
            flexDirection: "column",
            /* align-items: center; */
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ marginTop: "2rem", marginBottom: "2rem" }}
            variant="h3"
          >
            Tomorrow’s conversations start today. Build on-brand conversational
            companions, using the world’s most powerful AI language model.
            Compatible with WhatsApp, Instagram and Messenger.
          </Typography>

          <div className={styles.bodyContainer}>
            <Typography
              sx={{
                fontSize: "1.25rem",
                marginBottom: "1rem",
                color: "var(--fontSecondaryColor)",
              }}
            >
              BUILD YOUR BUDDY
            </Typography>
            {!loading && (
              <>
                <Buddy setFirstInteraction={setFirstInteraction} setBuddy={setBuddy} buddy={buddy} />
                <Purpose setFirstInteraction={setFirstInteraction} setPurpose={setPurpose} purpose={purpose} />
                <Personality
                  setFirstInteraction={setFirstInteraction}
                  setPersonalities={setPersonalities}
                  personas={personalities}
                />
                <Topic setFirstInteraction={setFirstInteraction} setTopic={setTopic} topic={topic} />
              </>
            )}
          </div>
        </Box>

        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            height: "100%",
            display: "flex",
            paddingTop: "var(--navHeight)",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: "10rem",
            alignItems: "center",
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Box
              ref={myRef}
              sx={{
                height: "600px",
                overflowY: "scroll",
                display: "flex",
                flexDirection: "column",
                ":first-of-type": { marginTop: "auto" },
              }}
            >
              {messages?.map((data, index) => (
                <Typography
                  variant="p"
                  key={index}
                  sx={{
                    width: { xs: "100%", md: "75%" },
                    maxWidth: "650px",
                    display: "flex",
                    fontSize: "1.5rem",
                    zIndex: "1",
                    marginTop: data.userMessage ? "2rem" : "auto",
                    marginBottom: data.userMessage ? "2rem" : "auto",
                    paddingTop: "2rem",
                    padding: "0 5% 0 7%",
                    justifyContent: "right",
                    alignItems: data.userMessage ? "right" : "left",
                  }}
                >
                  {data.message}
                </Typography>
              ))}
            </Box>
            <Box
              sx={{
                zIndex: 1,
                width: { xs: "100%", md: "75%" },
                marginTop: "2rem",
                padding: "0 5% 0 7%",
                color: "var(--color)",
              }}
            >
              <TextareaAutosize
                // disabled={allPresent() ? false : true}
                value={userInput}
                onKeyDown={() => handleKeyDown(event, "openai")}
                onChange={(e) => setUserInput(e.target.value)}
                ref={inputRef}
                className={styles.input}
                placeholder="ASK ME ANYTHING..."
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <div className={styles.canvasContainer}>
        <div className={styles.orbPosition}>
          <Orb
            cameraPos={1}
            prespectivePositionX={-0.5}
            prespectivePositionY={0.25}
            inputRef={inputRef}
          />
        </div>
      </div>
    </div>
  );
}
