import React, { useRef, useState, useEffect } from "react";
import cn from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import { Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Orb from "../../components/Orb";

import Buddy from "../../components/Chatbot/Buddy";
import Purpose from "../../components/Chatbot/Purpose";
import Personality from "../../components/Chatbot/Personality";
import Topic from "../../components/Chatbot/Topic";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

import authContext from "../../contexts/authContext";
import Nav from "../../components/Nav/Nav";

import { createAgent, Onboarding } from "../../api/Api";

import styles from "./style.module.css";

export default function Playground() {
  const inputRef = useRef("");
  const [userInput, setUserInput] = useState("");
  const [messages, setMessages] = useState([{}]);
  const [userMessage, setUserMessage] = useState();
  const [buddy, setBuddy] = useState("");
  const [purpose, setPurpose] = useState("");
  const [topic, setTopic] = useState("");
  const [personalities, setPersonalities] = useState([])
  const [firstInteraction, setFirstInteraction] = useState(false);
  const navigate = useNavigate();

  const myRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submitMessage();
    }
  };
  
  const handleAgentCreation = async () => {
    await createAgent(
      authContext.user.username,
      buddy,
      purpose,
      personalities,
      topic
    );
    navigate("/generate");
  };

  const allPresent = () => {
    if (buddy && purpose && topic && personalities) {
      return true;
    }
    return false;
  };

  const submitMessage = () => {
    setMessages([...messages, { message: userInput, userMessage: true }]);
    setUserMessage(true);
  };

  const fetchData = async () => {
    const response = await Onboarding({
      buddy,
      purpose,
      personalities,
      topic,
      userInput,
    });
    var data = response.data.response;
    setMessages([...messages, { message: data, userMessage: false }]);
    setUserMessage(false);
  };

  useEffect(() => {
    if (myRef) {
      myRef.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
    if (userMessage) {
      fetchData(userInput);
      setUserInput("");
    }
  }, [userMessage]);

  return (
    <div className={styles.container}>
      <Nav />
      <main className={styles.main}>
        <section className={cn(styles.section, styles.leftSection)}>
          <h3>
            Tomorrow’s conversations start today. Build on-brand conversational
            companions, using the world’s most powerful AI language model.
            Compatible with WhatsApp, Instagram and Messenger.
          </h3>

          <div className={styles.bodyContainer}>
            <Typography
              sx={{
                fontSize: "1.25rem",
                marginBottom: "1rem",
                color: "var(--fontSecondaryColor)",
              }}
            >
              BUILD YOUR BUDDY
            </Typography>
            <Buddy setFirstInteraction={setFirstInteraction} setBuddy={setBuddy} buddy={buddy} />
            <Purpose setFirstInteraction={setFirstInteraction} setPurpose={setPurpose} purpose={purpose} />
            <Personality setFirstInteraction={setFirstInteraction} setPersonalities={setPersonalities} personalities={personalities} />
            <Topic setFirstInteraction={setFirstInteraction} setTopic={setTopic} topic={topic} />
          </div>
        </section>

        <section
          ref={myRef}
          className={cn(styles.section, styles.rightSection)}
        >
          {messages?.map((data, index) => (
            <p
              key={index}
              className={
                data.userMessage ? styles.responseUser : styles.responseAi
              }
            >
              {data.message}
            </p>
          ))}
          <div className={styles.inputContainer}>
            <TextareaAutosize
              disabled={allPresent() ? false : true}
              value={userInput}
              onKeyDown={() => handleKeyDown(event, "openai")}
              onChange={(e) => setUserInput(e.target.value)}
              ref={inputRef}
              className={styles.input}
              placeholder="ASK ME ANYTHING..."
            />
          </div>
        </section>
      </main>

      <div className={styles.canvasContainer}>
        <div className={styles.orbPosition}>
          <Orb
            cameraPos={1}
            prespectivePositionX={-0.5}
            prespectivePositionY={0.25}
            inputRef={inputRef}
          />
        </div>
      </div>
      <Button
        disabled={allPresent() ? false : true}
        onClick={() => handleAgentCreation()}
        sx={{
          backgroundColor: "transparent",
          position: "absolute",
          bottom: 20,
          right: 60,
          border: "1px solid white",
          borderRadius: 25,
          color: "white",
          p: 2,
        }}
      >
        Build your own <TrendingFlatIcon sx={{ ml: 1 }} />
      </Button>
    </div>
  );
}
