import { TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Delete from '../../../assets/icons/Delete.svg'

export const ViewEditExample = ({ sentence, onUpdate, onDelete, index }) => {

    const [editing, setEditing] = useState(false);
    const [hovering, setHovering] = useState(false);
    const [mySentence, setNewSentence] = useState(sentence);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSave();
        }
    }

    const handleSave = () => {
        onUpdate(index, mySentence);
        setEditing(false);
    }

    const handleDelete = () => {
        onDelete(index);
        setEditing(false);
    }

    useEffect(() => {
        setNewSentence(sentence);
    }, [editing]);

    useEffect(() => {
        const handleMouseDown = (e) => {
            if (e.target.id !== 'outlined-basic' && e.target.id !== 'utter') {
                setEditing(false);
            }
        }
        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        }

    }, []);

    return (
        <div key={index} id="utter" style={{ marginTop: '18px' }}>
            {
                editing ? <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <TextField
                        onChange={(e) => setNewSentence(e.target.value)}
                        value={mySentence}
                        onKeyDown={handleKeyDown}
                        margin='none'
                        autoFocus={true}
                        placeholder='Type in a new phrase...'
                        id="outlined-basic"
                        variant="outlined"
                        sx={{
                            width: '100%',
                            background: '#2B2B2B',
                            borderRadius: '18px',
                            flex: '5',
                            marginRight: '12px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: 'none',
                                  borderRadius: '18px',
                                },
                                '&:hover fieldset': {
                                  borderColor: 'white',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: 'white',
                                },
                              },
                        }}
                    />
                </div> :
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignContent: 'center',
                            justifyContent: 'space-between'
                        }}
                        onMouseEnter={() => setHovering(true)}
                        onMouseLeave={() => setHovering(false)}
                    >
                        <Typography
                            onClick={() => setEditing(true)}
                            variant="h6"
                            style={{
                                paddingLeft: '16px',
                                fontWeight: 'normal',
                                cursor: 'text',
                                flex: '1',
                                color: hovering && '#767676'
                            }}>{sentence}</Typography>
                        {
                            hovering && <div
                                onClick={handleDelete}
                                style={{ cursor: 'pointer', display: 'flex' }}
                            >
                                <img src={Delete} />
                            </div>
                        }
                    </div>
            }
        </div>
    )
}
