import axios from "axios";

axios.defaults.headers.common["Content-Type"] = "application/json";

export const generateSentences = async (agent_id, postData) => {
  return await axios.post(
    `${window._env_.REACT_APP_API_URL}/generate/${agent_id}`,
    postData,
  );
};

export const createAgent = async (
  user,
  buddy,
  purpose,
  personalities,
  topic,
) => {
  try {
    const agent = await axios.post(`${window._env_.REACT_APP_API_URL}/agents`, {
      app_owner: user,
      name: buddy,
      purpose: purpose,
      personalities: personalities,
      topic: topic,
    });
    return agent;
  } catch (err) {
    console.error(err);
  }
};

// export const apiTokenRefresh = async (db, agent_id) => {
//   try {
//     const q = doc(db, "agents", agent_id);
//     await updateDoc(q, { api_key_last_refreshed: Date.now() });
//   } catch (err) {
//     console.error(err);
//     alert("An error occured while refreshing api key");
//   }
// };

export const Messages = async (agent_id, intents_id, postData) => {
  return await axios.post(
    `${window._env_.REACT_APP_API_URL}/chat/${agent_id}/${intents_id}`,
    postData,
  );
};

export const Onboarding = async (postData) => {
  return await axios.post(
    `${window._env_.REACT_APP_API_URL}/onboarding`,
    postData,
  );
};

export const trainModel = async (intent_id) => {
  return await axios.patch(
    `${window._env_.REACT_APP_API_URL}/train/${intent_id}`,
  );
};

export const scanAgents = async (query) => {
  try {
    const data = await axios.get(
      `${window._env_.REACT_APP_API_URL}/agents`,
      query,
    );
    return data.data;
  } catch (err) {
    console.log(err);
  }
};

export const getIntents = async (intent_id) => {
  try {
    const data = await axios.get(
      `${window._env_.REACT_APP_API_URL}/intent/${intent_id}`,
    );
    return data.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAgent = async (agent_id) => {
  try {
    const data = await axios.get(
      `${window._env_.REACT_APP_API_URL}/agent/${agent_id}`,
    );
    return data.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateAgentItem = async (agent_id, data) => {
  try {
    return await axios.patch(
      `${window._env_.REACT_APP_API_URL}/agent/${agent_id}`,
      data,
    );
  } catch (err) {
    console.log(err);
  }
};

export const updateIntentItem = async (intent_id, data) => {
  try {
    return await axios.patch(
      `${window._env_.REACT_APP_API_URL}/intent/${intent_id}`,
      data,
    );
  } catch (err) {
    console.log(err);
  }
};

// export const deleteItem = async (query) => {
//   try {
//     const data = await ddb.deleteItem(query).promise();
//     return data;
//   } catch (err) {
//     console.log(err);
//   }
// };

const getStatus = async (domain) => {
  const status = await axios.get(
    domain || `${window._env_.REACT_APP_API_URL}/status`,
  );
  return status.status;
};
