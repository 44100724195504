import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import Info from "../../assets/icons/Info.svg";

import Tooltip from "../../components/Tooltip/Tooltip";
import ModelBox from "../PersonalitySelection/PersonalitySelection";

export default function Personality({
  setPersonalities,
  personas,
  setFirstInteraction,
}) {
  const [tooltip, setTooltip] = useState();
  const [personalityActive, setPersonalityActive] = useState(false);
  const [personalitiesSelected, setPersonalitiesSelected] = useState(
    personas ? personas : []
  );

  const personality = "bot personality";

  const toolTip = (type) => {
    setTooltip(type);
  };

  const closeModelBox = () => {
    setPersonalityActive(false);
  };

  const personalities = (values) => {
    setPersonalitiesSelected(values);
  };

  useEffect(() => {
    setPersonalities(personalitiesSelected);
    setFirstInteraction(true);
  }, [personalitiesSelected]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          fontSize: "1.5rem",
          marginBottom: "1.875rem",
          color: "var(--fontSecondaryColor)",
        }}
      >
        I can be{" "}
        {personalitiesSelected.length <= 0 ? (
          <Box
            onClick={() => setPersonalityActive(true)}
            sx={{
              border: "1px solid grey",
              borderRadius: "4px",
              display: "inline-block",
              color: "grey",
              cursor: "pointer",
              fontSize: "1.25rem",
              p: "0.25rem",
              opacity: "0.4",
            }}
          >
            {personality}
            {/* <Box component="img" src={<ArrowRightIcon />} /> */}
          </Box>
        ) : (
          <Box
            onClick={() => setPersonalityActive(true)}
            sx={{ display: "inline-block" }}
          >
            {personalitiesSelected.map((item, index) => (
              <Typography
                key={index}
                sx={{
                  display: "inline-block",
                  textDecoration: "underline",
                  fontSize: "1.5rem",
                  textTransform: "lowercase",
                  whiteSpace  : 'break-spaces',
                }}
              >
                {index === personalitiesSelected.length - 1 &&
                personalitiesSelected.length === 3
                  ? "and " + item
                  : `${item}, `}
              </Typography>
            ))}
          </Box>
        )}
        {personalityActive && (
          <Box sx={{ display: "inline-block" }}>
            <ModelBox
              closeModelBox={closeModelBox}
              personalities={personalities}
              personalitiesSelected={personalitiesSelected}
            />
          </Box>
        )}
        {tooltip !== "personality" && (
          <Box
            component="img"
            onClick={() => toolTip("personality")}
            src={Info}
            sx={{
              height: "1.35rem",
              display: "inline-block",
              cursor: "pointer",
              verticalAlign: "middle",
              ml: 1,
            }}
            alt="info"
          />
        )}
      </Box>
      {tooltip == "personality" && (
        <Box sx={{ display: "inline-block", pl: 1.25 }}>
          <Tooltip
            title={"Tell us, what’s your bot’s tone of voice? For example:"}
            body={"Friendly, Encouraging and Playful"}
            closeToolTip={toolTip}
          />
        </Box>
      )}
    </Box>
  );
}
