import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Typography, Box } from "@mui/material";

import Nav from "../components/Nav/Nav";
import Orb from "../components/Orb";

const copyExamples = [
  "Whilst you’re here, we’d like to notify you of the safeguarding steps we have taken to protect users. Using the OpenAI content filter, problematic responses generated will be flagged and reported and won’t be visible to the user.",
  "You are also able to manage your buddy’s responses via our Intents Admin Panel. However, this AI technology is cutting-edge and there is still risk involved, so please continue with caution."
];

export default function Generate() {
  const [newCopy, setNewCopy] = useState("Please be patient as we program your buddy.");
  const inputRef = useRef("");
  const navigate = useNavigate();
  const location = useLocation();

  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * copyExamples.length);
    setNewCopy(copyExamples[index]);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (location.pathname === '/generate'){
        navigate("/dashboard")
      }
    }, 50000);
    const intervalID = setInterval(shuffle, 5000);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Nav />
      <Box
        sx={{
          position: "absolute",
          left: "0px",
          top: "25%",
          right: "0px",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            m: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{ color: "white", fontSize: 64, fontWeight: 700, pb: 1.5 }}
          >
            Generating your buddy
          </Typography>
          <Typography
            sx={{ color: "white", fontSize: 17, fontWeight: 500, opacity: 0.5, maxWidth: '520px'}}
          >
            {newCopy}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ height: "1080px", opacity: 0.4 }}>
        <Orb
          prespectivePositionX={-0.0}
          prespectivePositionY={0.012}
          cameraPos={4}
          inputRef={inputRef}
        />
      </Box>
    </Box>
  );
}
