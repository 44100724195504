import axios from "axios";
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";

const authContext = {
  userPool: null,
  user: null,
  isAuthenticated: false,
  session: JSON.parse(window.localStorage.getItem("AWSCognitoSession")),

  init: function () {
    // define user pool
    const poolData = {
      UserPoolId: window._env_.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: window._env_.REACT_APP_COGNITO_CLIENT_ID,
    };
    this.userPool = new CognitoUserPool(poolData);
    // check if user is already authenticated
    this.user = this.userPool.getCurrentUser();
    if (this.user !== null) {
      this.isAuthenticated = true;
      this.user.refreshSession(
        new CognitoRefreshToken({
          RefreshToken: this.session.refreshToken.token,
        }),
        (err, session) => {
          if (err) {
            console.log(err);
            return;
          }
          this.session = session;
          window.localStorage.setItem(
            "AWSCognitoSession",
            JSON.stringify(this.session)
          );
        }
      );
      this.user.getSession((error, session) => {
        this.session = session;
      });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.session.accessToken.jwtToken}`;
    } else {
      window.localStorage.removeItem("AWSCognitoSession");
    }
  },

  login: function (username, password) {
    const authenticationData = {
      Username: username,
      Password: password,
    };

    const userData = {
      Username: username,
      Pool: this.userPool,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve, reject) =>
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          this.isAuthenticated = true;
          this.user = this.userPool.getCurrentUser();
          this.session = result;
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.session.accessToken.jwtToken}`;
          window.localStorage.setItem(
            "AWSCognitoSession",
            JSON.stringify(this.session)
          );
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          delete userAttributes.email_verified;
          delete userAttributes.email;

          window.sessionUserAttributes = userAttributes;
        },
      })
    );
  },

  register: function (username, password, email) {
    // define user attributes
    const attributeList = [];
    const dataEmail = {
      Name: "email",
      Value: email,
    };
    const attributeEmail = new CognitoUserAttribute(dataEmail);
    attributeList.push(attributeEmail);

    // call register
    this.userPool.signUp(
      username,
      password,
      attributeList,
      null,
      (err, result) => {
        if (err) {
          console.log(err);
          return;
        }

        // result.user is a CognitoUser object
        this.user = result.user;
      }
    );
  },
  // reset password
  changePassword: function (oldPassword, newPassword) {
    this.user = this.userPool.getCurrentUser();
    if (this.user) {
      return new Promise((resolve, reject) =>
        this.user.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            this.user.changePassword(
              oldPassword,
              newPassword,
              (err, result) => {
                if (err) {
                  reject(err);
                }
                resolve(result);
              }
            );
          }
        })
      );
    }
  },

  logout: function () {
    this.user.signOut();
    this.isAuthenticated = false;
  },
};

export default authContext;
