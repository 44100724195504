import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Fab } from "@mui/material"

export const CreateNewIntentButton = ({ onSave }) => {

    const [open, setOpen] = React.useState(false);
    const [intentName, setIntentName] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleSave = () => {
        onSave(intentName)
        setOpen(false);
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Fab
                onClick={handleClickOpen}
                size="large"
                variant="extended"
                style={{
                    width: '100%'
                }}
            >
                New Intent
            </Fab>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>+ New Intent</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="New Intent Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setIntentName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>


    )
}